<script>

export default {
  name: 'AlertmanagerConfig',

  data() {
    return {};
  },

};
</script>

<template>
  <div>AlertmanagerConfig</div>
</template>

<style lang='scss' scoped>
.header{
  display: flex;
  H1{
    flex: 1;
  }
}
</style>
